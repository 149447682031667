import { AuthRepository } from 'core';
import { pluck } from 'rxjs/operators';
import { energyVectorIcons } from 'shared-reference';

import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

import { TooltipContextHolderService } from './shared/components';

@Component({
  selector: 'greenbackup-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  userProfileName$ = this.authRepository.user$.pipe(pluck('name'));

  constructor(
    private authRepository: AuthRepository,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private http: HttpClient,
    private tooltipContextHolderService: TooltipContextHolderService,
  ) {}

  ngOnInit() {
    this.initializeEnergyVectorIcons();
    this.initializeContextualHelp();
  }

  logout(): void {
    this.authRepository.logout();
  }

  private initializeEnergyVectorIcons() {
    Object.keys(energyVectorIcons).forEach((key) => {
      this.matIconRegistry.addSvgIcon(
        key,
        this.domSanitizer.bypassSecurityTrustResourceUrl(`../${energyVectorIcons[key]}`),
      );
    });
  }

  private initializeContextualHelp(): void {
    this.http
      .get('assets/help/green_backup_tooltips.json')
      .subscribe((data) => this.tooltipContextHolderService.setContextualData(data));
  }
}
