import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormRecord, NonNullableFormBuilder, UntypedFormBuilder } from '@angular/forms';
import { Criteria, MainCriteria, NameValueBoolean } from 'shared-models';
import { CriteriaService } from '../../services';
import { CriteriaRepository } from '../../store';
import { combineLatest } from 'rxjs';
import { filter, first, map } from 'rxjs/operators';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';

export interface CriteriaForm {
  backup: FormControl<boolean>;
  energyVectors: FormRecord<FormControl<boolean>>;
}
@Component({
  selector: 'greenbackup-criteria',
  templateUrl: './criteria.component.html',
  styleUrls: ['./criteria.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CriteriaComponent implements OnInit {
  loading$ = this.criteriaRepository.loading$;
  backup$ = this.criteriaRepository.backup$;
  energyVectors$ = this.criteriaRepository.energyVectors$.pipe(
    map((energyVectors) => Object.keys(energyVectors).map((key) => ({ name: key, value: energyVectors[key] }))),
  );
  mainCriteria: Array<MainCriteria>;

  form = new FormGroup<Partial<CriteriaForm>>({});
  backupFormControl = new FormControl<boolean>(true);
  energyVectorsFormGroup = new FormRecord<FormControl<boolean>>({});
  criteriaFormGroup = new FormRecord<FormControl<number>>({});

  constructor(private criteriaRepository: CriteriaRepository, public fb: NonNullableFormBuilder) {}

  private prepareCriteriaValue({ id, name, value }): Array<Criteria> {
    const { criteria } = this.criteriaRepository.getEntity(id);

    return criteria.map((data) => {
      if (data.name === name) {
        return {
          name,
          value,
        };
      }
      return data;
    });
  }

  private initEnergyVectorsFormGroup(energyVectors: Array<NameValueBoolean>) {
    energyVectors.forEach(({ name, value }) => {
      this.energyVectorsFormGroup.addControl(name, new FormControl(value));
    });
    this.form.addControl('energyVectors', this.energyVectorsFormGroup);
  }

  private initBackupFormControl(backup: boolean): void {
    this.backupFormControl.setValue(backup);
    this.form.addControl('backup', this.backupFormControl);
  }

  private initCriteriaForm(mainCriteria: Array<MainCriteria>): void {
    mainCriteria.forEach(({ name, criteria }) => {
      let mainCriteriaFormGroup = {};
      criteria.forEach(({ name: criteriaName, value }) => {
        mainCriteriaFormGroup = {
          ...mainCriteriaFormGroup,
          [criteriaName]: new FormControl(value),
        };
      });
      this.form.addControl(name, new FormGroup(mainCriteriaFormGroup));
    });
  }

  onEnergyVectorToggle(event: Record<string, boolean>) {
    this.criteriaRepository.updateEnergyVector(event);
  }

  onSliderChange(event) {
    const criteria = this.prepareCriteriaValue(event);
    this.criteriaRepository.updateCriteria(event.id, criteria);
  }

  onBackupChange({ checked }: MatSlideToggleChange) {
    this.criteriaRepository.updateBackup(checked);
  }

  ngOnInit(): void {
    combineLatest([this.loading$, this.backup$, this.energyVectors$])
      .pipe(
        filter(([loading]) => !loading),
        map(([_, backup, energyVectors]) => ({
          backup,
          energyVectors,
        })),
        first(),
      )
      .subscribe(({ backup, energyVectors }) => {
        this.mainCriteria = this.criteriaRepository.getAllEntities();
        this.initEnergyVectorsFormGroup(energyVectors);
        this.initBackupFormControl(backup);
        this.initCriteriaForm(this.mainCriteria);
      });
  }
}
