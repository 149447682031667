import { Injectable } from '@angular/core';
import { select } from '@ngneat/elf';
import { setEntities, selectAllEntities, updateEntities, getEntity, getAllEntities } from '@ngneat/elf-entities';
import { map } from 'rxjs/operators';
import { APIResponseMainCriteria, Criteria, MainCriteria } from 'shared-models';
import { store } from './criteria.store';
@Injectable()
export class CriteriaRepository {
  mainCriteria$ = store.pipe(selectAllEntities());

  loading$ = store.pipe(select((state) => state.loading));
  backup$ = store.pipe(select((state) => state.backup));
  energyVectors$ = store.pipe(select((state) => state.energyVectors));
  criteria$ = this.mainCriteria$.pipe(map((criteria) => criteria.reduce((acc, cur) => [...acc, ...cur.criteria], [])));

  constructor() {}

  private prepareEntities(data: APIResponseMainCriteria): Array<MainCriteria> {
    return Object.keys(data).map((key, index) => {
      const id = index + 1;
      const name = data[key].label;
      const criteria = data[key].criteria.map((criteriaName) => ({
        name: criteriaName,
        value: 0,
      }));
      return {
        id,
        name,
        criteria,
      };
    });
  }

  initialize(data: APIResponseMainCriteria): void {
    const enitities = this.prepareEntities(data);
    store.update(setEntities(enitities), (state) => ({
      ...state,
      loading: false,
    }));
  }

  getAllEntities(): Array<MainCriteria> {
    return store.query(getAllEntities());
  }

  getEntity(id: number): MainCriteria {
    return store.query(getEntity(id));
  }

  updateCriteria(id: number, criteria: Array<Criteria>): void {
    store.update(
      updateEntities(id, (entity) => ({
        ...entity,
        criteria,
      })),
    );
  }

  updateBackup(backup: boolean): void {
    store.update((prevState) => ({
      ...prevState,
      backup,
    }));
  }

  updateEnergyVector(energyVector: Record<string, boolean>): void {
    store.update((prevState) => ({
      ...prevState,
      energyVectors: {
        ...prevState.energyVectors,
        ...energyVector,
      },
    }));
  }
}
