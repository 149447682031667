/* eslint-disable @angular-eslint/no-input-rename */
import { ChangeDetectionStrategy, Component, Input, OnInit, ViewChild } from '@angular/core';
import { ChartjsComponent } from '@ctrl/ngx-chartjs';
import { ChartData, ChartOptions } from 'chart.js';

type ChartInstanceData = {
  data: Array<number>;
  labels: Array<string>;
  colors: Array<string>;
};

@Component({
  selector: 'greenbackup-bar-chart',
  templateUrl: './bar-chart.component.html',
  styleUrls: ['./bar-chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BarChartComponent implements OnInit {
  chartData: ChartData;
  chartOptions: ChartOptions;
  private _data: ChartInstanceData = null;
  @ViewChild('ref', { static: true }) ref!: ChartjsComponent;

  @Input('data') set data(data: ChartInstanceData) {
    this._data = data;
    this.updateChart(data);
  }
  get data() {
    return this._data;
  }

  @Input() label: string;
  @Input() legends: Array<{ name: string; color: string }>;

  initChartData(): void {
    this.chartData = {
      labels: [],
      datasets: [
        {
          data: [],
          fill: true,
          backgroundColor: [],
          borderWidth: 1,
        },
      ],
    };
  }

  initChartOption(): void {
    this.chartOptions = {
      responsive: true,
      plugins: {
        legend: {
          display: false,
        },
        title: {
          display: false,
        },
      },
    };
  }

  updateChart({ data, labels, colors }: ChartInstanceData) {
    if (this.ref.chartInstance) {
      this.ref.chartInstance.data.labels = labels;
      this.ref.chartInstance.data.datasets[0].data = data;
      this.ref.chartInstance.data.datasets[0].backgroundColor = colors;
      this.ref.updateChart();
    }
  }

  ngOnInit(): void {
    this.initChartData();
    this.initChartOption();
  }
}
