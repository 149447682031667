import { Component, OnInit } from '@angular/core';
import { APIResponseCriteriaMatrix } from 'shared-models';
import { CriteriaService } from '../../services';
import { CriteriaRepository, ProjectDataRepository, TechnologiesRepository } from '../../store';
import { first } from 'rxjs/operators';
@Component({
  selector: 'greenbackup-main-page',
  templateUrl: './main-page.component.html',
  styleUrls: ['./main-page.component.scss'],
})
export class MainPageComponent implements OnInit {
  constructor(
    private criteriaService: CriteriaService,
    private criteriaRepository: CriteriaRepository,
    private technologiesRepository: TechnologiesRepository,
    private projectDataRepository: ProjectDataRepository,
  ) {}

  ngOnInit(): void {
    this.criteriaService
      .downloadMainCriteria()
      .pipe(first())
      .subscribe((response: APIResponseCriteriaMatrix) => {
        const {
          criteria,
          technologies,
          categories,
          others,
          last_updated_date: lastUpdatedDate,
          contact_email_addresses: contactEmailAddresses,
        } = response;
        this.criteriaRepository.initialize(criteria);
        this.technologiesRepository.initialize({
          technologies,
          categoriesColor: categories,
          others,
        });
        this.projectDataRepository.initialize({
          lastUpdatedDate,
          contactEmailAddresses,
        });
      });
  }
}
