import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { ReactiveComponentModule } from '@ngrx/component';
import { DatasheetsModule } from '../datasheets/datasheets.module';
import { TechnologiesComponent } from './technologies.component';

@NgModule({
  declarations: [TechnologiesComponent],
  imports: [CommonModule, DatasheetsModule, MatButtonModule, MatIconModule, ReactiveComponentModule],
  exports: [TechnologiesComponent],
})
export class TechnologiesModule {}
