<div fxLayout="column" fxLayoutAlign="center center">
  <div
    data-test="legend-container"
    *ngIf="!!legends?.length && !!data?.data?.length"
    class="legend-container"
    fxLayoutAlign="center center"
  >
    <span data-test="legend" *ngFor="let legend of legends" fxLayoutAlign="center center">
      <span class="legend" data-test="legend-color" [style.backgroundColor]="legend.color"></span>
      {{ legend.name }}
    </span>
  </div>
  <ngx-chartjs #ref type="bar" [data]="chartData" [options]="chartOptions"></ngx-chartjs>
  <label data-test="chart-label" *ngIf="label" [innerHTML]="label"></label>
</div>
