import { InjectionToken } from '@angular/core';

export const energyVectorIcons = {
  gas: 'assets/icons/energy-vectors/gas_00.svg',
  diesel: 'assets/icons/energy-vectors/diesel_00.svg',
  electricity: 'assets/icons/energy-vectors/electricity_00.svg',
  hydrogen: 'assets/icons/energy-vectors/hydrogen_00.svg',
  biofuel: 'assets/icons/energy-vectors/biomass_00.svg',
};

export const energyVectorName = {
  gas: 'Natural Gas',
  diesel: 'Diesel',
  electricity: 'Electricity',
  hydrogen: 'Hydrogen',
  biofuel: 'Biofuel',
};

export const ZERO_VALUE_CRITERIA_IN_FUTURE = new InjectionToken<Array<string>>('zero-value-criteria-in-future', {
  factory: () => Object.values(['CAPEX', 'OPEX', 'Technology readiness']),
  providedIn: 'root',
});

export const ENVIRONMENT_CRITERIA = new InjectionToken<Array<string>>('environment-criteria', {
  factory: () => Object.values(['SOx emissions', 'CO2 emissions', 'Physical footprint']),
  providedIn: 'root',
});

export const LIBRARY_ENERGY_STORAGE_OPTIONS = new InjectionToken<Array<string>>('library-energy-storage-options', {
  factory: () => Object.values(['generators', 'storages', 'others']),
  providedIn: 'root',
});
