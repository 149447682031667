import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { ReactiveComponentModule } from '@ngrx/component';
import { SharedModule } from 'src/app/shared';

import { LibraryComponent } from './library.component';

@NgModule({
  declarations: [LibraryComponent],
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    MatSelectModule,
    ReactiveFormsModule,
    ReactiveComponentModule,
    SharedModule,
  ],
  exports: [LibraryComponent],
})
export class LibraryModule {}
