<section class="technologies-section">
  <h2>Decarbonization roadmap</h2>
  <div data-test="arrow" class="arrow-body">
    <label data-test="start-year">2025</label>
    <label data-test="end-year">2035</label>

    <div class="arrow-head"></div>
  </div>
  <ng-container *ngIf="techAndFutureTech$ | ngrxPush as allTechnologies">
    <section
      *ngIf="
        allTechnologies.technologies.length > 0 || allTechnologies.futureTechnologies.length > 0;
        else emptyTechnologies
      "
      class="technologies-buttons-section"
    >
      <div class="technologies-buttons">
        <button
          *ngFor="let technology of allTechnologies.technologies"
          mat-flat-button
          color="primary"
          [style]="getTechnologyButtonStyle(technology)"
          [attr.data-test]="technology.name"
          (click)="onSelectTechnology(technology.name)"
        >
          {{ technology.name }}
        </button>
      </div>

      <div class="technologies-buttons">
        <button
          *ngFor="let technology of allTechnologies.futureTechnologies"
          mat-flat-button
          color="primary"
          [style]="getTechnologyButtonStyle(technology)"
          [attr.data-test]="'future-' + technology.name"
          (click)="onSelectTechnology(technology.name)"
        >
          {{ technology.name }}
        </button>
      </div>
    </section>
    <greenbackup-datasheets
      data-html2canvas-ignore
      [attr.data-test]="'datasheets-' + (selectedTechnology$ | ngrxPush)"
      [selectedTechnology]="selectedTechnology$ | ngrxPush"
    ></greenbackup-datasheets>
  </ng-container>
</section>

<ng-template #emptyTechnologies>
  <section data-test="select-criteria-message" class="select-criteria-message">
    <p>Please select main criteria first to view relevant technologies.</p>
  </section>
</ng-template>
