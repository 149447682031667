import { createStore, withProps } from '@ngneat/elf';
import { withEntities } from '@ngneat/elf-entities';
import { MainCriteria } from 'shared-models';
import { energyVectorIcons } from 'shared-reference';

const getInitialEnergyVector = (initial = true): Record<string, boolean> =>
  Object.keys(energyVectorIcons).reduce(
    (acc, cur) => ({
      ...acc,
      [cur]: initial,
    }),
    {},
  );

export const store = createStore(
  { name: 'criteria' },
  withEntities<MainCriteria>(),
  withProps<{
    loading: boolean;
    backup: boolean;
    energyVectors: Record<string, boolean>;
  }>({
    loading: true,
    backup: true,
    energyVectors: getInitialEnergyVector(),
  }),
);
