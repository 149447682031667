/* eslint-disable @typescript-eslint/naming-convention */

import { NameValueNumber } from './common.model';
import { Criteria } from './criteria.model';
import { Entity } from './enitity.model';

export enum TechnologyCategory {
  Generators = 'generators',
  Storages = 'storages',
  Microgrids = 'microgrids',
}

export interface Technology extends Entity {
  category: TechnologyCategory | string;
  backup: boolean;
  criteria: Array<Criteria>;
  color?: string;
  fuels?: Array<string>;
}

export interface TechnologyScore extends NameValueNumber {
  category?: TechnologyCategory | string;
  fuels?: Array<string>;
}

export type TechnologyRank = Array<TechnologyScore>;
export type ComputeTechnologies = {
  criteria: Array<Criteria>;
  technologies: Array<Technology>;
  backup: boolean;
  energyVectors: Record<string, boolean>;
};
