import { Injectable } from '@angular/core';
import html2pdf from 'html-to-pdf-js';

@Injectable({
  providedIn: 'root',
})
export class HtmlToPdfService {
  private worker: html2pdf;
  workerCanvas: any;
  workerSaver: any;

  private initialOptions = {
    margin: 0,
    filename: `greenbackup-report.pdf`,
    image: { type: 'png', quality: 0.98 },
    html2canvas: { scale: 1 },
    jsPDF: { unit: 'px', format: 'legal', orientation: 'portrait', hotfixes: ['px_scaling'] },
    pagebreak: { mode: ['css', 'avoid-all'] },
  };
  constructor() {}

  createCanvas(html, options = {}) {
    this.worker = html2pdf();
    this.workerCanvas = this.worker.set({
      ...this.initialOptions,
      ...options,
    });
    this.workerSaver = this.workerCanvas.from(html);
  }

  downloadHTMLToPDF() {
    this.workerSaver.save();
  }
}
