<section class="datasheet-section" *ngIf="_selectedTechnology">
  <div fxLayout="row">
    <div *ngIf="isDatasheetLoaded && totalPages > 1" class="pagination" fxLayout="row" fxLayoutAlign="end" fxFlex>
      <button mat-button (click)="prevPage()" [disabled]="page === 1" [attr.data-test]="'previous'">
        <mat-icon>navigate_before</mat-icon>
      </button>
      <span [attr.data-test]="'page'">{{ page }} / {{ totalPages }}</span>
      <button mat-button (click)="nextPage()" [disabled]="page === totalPages" [attr.data-test]="'next'">
        <mat-icon>navigate_next</mat-icon>
      </button>
    </div>
    <div *ngIf="isDatasheetLoaded" class="download" fxLayout="row" fxLayoutAlign="end" fxFlex>
      <button mat-button class="download-button" (click)="onDownload()" [attr.data-test]="'download'">
        <mat-icon>save_alt</mat-icon>
        <span>Download as PDF</span>
      </button>
    </div>
  </div>
  <ng-container *ngIf="!fetchingPDF; else preloader">
    <ngx-extended-pdf-viewer
      [src]="datasheet"
      [zoom]="'75%'"
      [pageViewMode]="'single'"
      [showToolbar]="false"
      [showBorders]="false"
      [backgroundColor]="'transparent'"
      [page]="page"
      [handTool]="false"
      height="880px"
      (pagesLoaded)="afterDatasheetLoadingComplete($event)"
      [attr.data-test]="'datasheets-page-' + page"
    ></ngx-extended-pdf-viewer>
  </ng-container>
</section>

<ng-template #preloader>
  <div *ngIf="hasTechnologySelected" class="preloader-container" fxLayoutAlign="center center">
    <div class="sk-chase">
      <div class="sk-chase-dot"></div>
      <div class="sk-chase-dot"></div>
      <div class="sk-chase-dot"></div>
      <div class="sk-chase-dot"></div>
      <div class="sk-chase-dot"></div>
      <div class="sk-chase-dot"></div>
    </div>
  </div>
</ng-template>
