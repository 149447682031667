import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderModule, FooterModule, SliderModule } from './components';
import { ReactiveComponentModule } from '@ngrx/component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { BarChartModule } from './components/bar-chart';
import { EnergyVectorModule } from './components/energy-vector';
import { FlexLayoutModule } from '@angular/flex-layout';

@NgModule({
  imports: [CommonModule],
  exports: [
    HeaderModule,
    FooterModule,
    SliderModule,
    ReactiveComponentModule,
    NgxSkeletonLoaderModule,
    BarChartModule,
    EnergyVectorModule,
    FlexLayoutModule,
  ],
  declarations: [],
})
export class SharedModule {}
