import { ChangeDetectionStrategy, Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { energyVectorName } from 'shared-reference';
@Component({
  selector: 'greenbackup-energy-vector',
  templateUrl: './energy-vector.component.html',
  styleUrls: ['./energy-vector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EnergyVectorComponent),
      multi: true,
    },
  ],
})
export class EnergyVectorComponent implements ControlValueAccessor {
  @Input() name: string;
  @Input() isSelected: boolean;
  @Output() toggleChange = new EventEmitter<Record<string, boolean>>();

  constructor() {}

  onChanged = (value: boolean) => {};

  writeValue(obj: any): void {}
  registerOnChange(fn: any): void {
    this.onChanged = fn;
  }
  registerOnTouched(fn: any): void {}

  toggleSelected(): void {
    this.isSelected = !this.isSelected;
    this.onChanged(this.isSelected);
    this.toggleChange.emit({ [this.name]: this.isSelected });
  }

  getEnergyVectorName(name): string {
    return energyVectorName[name];
  }
}
