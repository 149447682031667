<section class="library-container">
  <h2 class="section-header">Library</h2>
  <section class="library-section">
    <ng-container *ngIf="!(loading$ | ngrxPush); else preloader">
      <form [formGroup]="form">
        <div fxLayout="row">
          <div fxLayout="row" fxLayoutAlign="start">
            <mat-form-field appearance="fill">
              <mat-label>Energy Storages</mat-label>
              <mat-select [attr.data-test]="'energy-option-select'" formControlName="energyStorage">
                <mat-option
                  *ngFor="let option of libraryEnergyStorageOptions"
                  [value]="option"
                  [attr.data-test]="option"
                >
                  {{ option }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div fxLayout="row" fxLayoutAlign="start" fxFlex>
            <div class="download-full-datasheet">
              <a
                mat-button
                class="download-button"
                type="button"
                color="primary"
                (click)="onDownloadFullDatasheet()"
                [disabled]="isDownloadingFullDatasheet$ | ngrxPush"
                [attr.data-test]="'download-full-datasheet'"
              >
                <mat-icon *ngIf="!(isDownloadingFullDatasheet$ | ngrxPush); else spinner">save_alt</mat-icon>
                <span>Download full datasheet</span>
              </a>
            </div>
          </div>
        </div>

        <div class="library-technologies" *ngIf="filteredTechnologies$ | ngrxPush as filteredTechnologies">
          <button
            [attr.data-test]="technology.name"
            *ngFor="let technology of filteredTechnologies"
            mat-flat-button
            [style]="technology.style"
            color="primary"
            (click)="viewPDF(technology.name)"
            [disabled]="form.disabled"
          >
            {{ technology.name }}
          </button>
        </div>

        <article class="library-article">
          Acknowledgement:<br />
          The technical datasheet has been produced by Laborelec. The website has been produced by Engie Impact -
          Advanced Analytics. All the work has been funded by ENGIE EYES Research lab.
        </article>

        <p class="contact-emails">
          <a data-test="contact-emails" [attr.href]="'mailto:' + (contactEmailAddresses$ | ngrxPush)">Contact us</a>
        </p>
      </form>
      <p class="last-update" data-test="last-updated-date">Data last update: {{ lastUpdatedDate$ | ngrxPush }}</p>
    </ng-container>
  </section>
</section>

<ng-template #spinner>
  <mat-icon [class.spinner]="true"></mat-icon>
</ng-template>

<ng-template #preloader>
  <div
    gdAreas="energy download . .  | technology technology  technology .  | . article article. | . . . date"
    gdGap="0 20px"
    class="preload-container"
  >
    <ngx-skeleton-loader gdArea="energy" count="1"></ngx-skeleton-loader>
    <ngx-skeleton-loader gdArea="download" count="1"></ngx-skeleton-loader>
    <ngx-skeleton-loader gdArea="technology" count="1"></ngx-skeleton-loader>
    <ngx-skeleton-loader gdArea="article" count="3"></ngx-skeleton-loader>
    <ngx-skeleton-loader gdArea="date" count="1"></ngx-skeleton-loader>
  </div>
</ng-template>
