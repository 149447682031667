import { Injectable } from '@angular/core';
import { select } from '@ngneat/elf';
import { store } from './project-data.store';
@Injectable()
export class ProjectDataRepository {
  lastUpdatedDate$ = store.pipe(select((state) => state.lastUpdatedDate));
  contactEmailAddresses$ = store.pipe(select((state) => state.contactEmailAddresses));

  constructor() {}

  initialize({ lastUpdatedDate, contactEmailAddresses }) {
    store.update((state) => ({
      ...state,
      loading: false,
      lastUpdatedDate,
      contactEmailAddresses,
    }));
  }
}
