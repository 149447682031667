import { Options } from '@angular-slider/ngx-slider';
import { Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'greenbackup-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SliderComponent),
      multi: true,
    },
  ],
})
export class SliderComponent implements ControlValueAccessor {
  @Input() label: string;
  @Input() showSliderLabel: boolean;
  @Input() sliderLabel: [string, string];
  @Input() tooltipText: string;
  @Output() sliderChange = new EventEmitter<number>();
  options: Options = {
    showTicksValues: false,
    showTicks: true,
    stepsArray: [{ value: 0 }, { value: 1 }, { value: 2 }, { value: 3 }, { value: 4 }, { value: 5 }],
  };

  onChanged = (param: unknown) => {};

  constructor() {}
  writeValue(obj: any): void {}
  registerOnChange(fn: any): void {
    this.onChanged = fn;
  }
  registerOnTouched(fn: any): void {}

  onChangeSlider(value: number): void {
    this.onChanged(value);
    this.sliderChange.emit(value);
  }
}
