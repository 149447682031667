import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OktaAuthModule, OKTA_CONFIG } from '@okta/okta-angular';
import { OktaAuth } from '@okta/okta-auth-js';
import { environment } from 'environments';
import { HttpClientModule } from '@angular/common/http';

@NgModule({
  declarations: [],
  imports: [HttpClientModule, CommonModule, OktaAuthModule],
  providers: [
    {
      provide: OKTA_CONFIG,
      useValue: { oktaAuth: new OktaAuth(environment.auth) },
    },
  ],
})
export class CoreModule {}
