import { API_BASE_URL } from 'shared-tokens';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class CriteriaService {
  constructor(@Inject(API_BASE_URL) private apiBaseUrl: string, private http: HttpClient) {}

  downloadMainCriteria() {
    return this.http.get(`${this.apiBaseUrl}/criteria_matrix`);
  }
}
