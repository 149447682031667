<span
  [attr.data-test]="'energy-vector-' + name"
  (click)="toggleSelected()"
  fxLayout="column"
  fxLayoutAlign="center center"
  [ngClass]="{ selected: isSelected }"
>
  <mat-icon [attr.data-test]="'icon-' + name" svgIcon="{{ name }}"></mat-icon>
  <label data-test="name">{{ getEnergyVectorName(name) }}</label>
</span>
