/* eslint-disable @typescript-eslint/naming-convention */
import { CookieService } from 'ngx-cookie';
import { iif, Observable } from 'rxjs';
import { mergeMap, first } from 'rxjs/operators';

import { HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { AuthRepository } from './auth.repository';

const NONCE_COOKIE = 'okta-oauth-nonce';

const RESPONSE_TYPE = {
  ARRAY_BUFFER: 'arraybuffer',
  JSON: 'json',
};

@Injectable({ providedIn: 'root' })
export class AuthInterceptor implements HttpInterceptor {
  constructor(private authRepository: AuthRepository, private cookieService: CookieService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return this.authRepository.tokens$.pipe(
      first(),
      mergeMap((tokens) =>
        iif(
          () => ['json', 'arraybuffer'].includes(request.responseType),
          this.addAuthBearerToken(tokens, request, next),
          next.handle(request),
        ),
      ),
    );
  }

  addAuthBearerToken(tokens, request, next) {
    let headers: HttpHeaders = request.headers;
    const responseType: any = request.responseType === 'arraybuffer' ? RESPONSE_TYPE.ARRAY_BUFFER : RESPONSE_TYPE.JSON;
    if (!!tokens && !!tokens.accessToken) {
      headers = headers.set('Authorization', [`Bearer ${tokens.idToken}`, this.cookieService.get(NONCE_COOKIE)]);
    }
    const requestClone = request.clone({
      responseType,
      headers,
    });
    return next.handle(requestClone);
  }
}
