<header data-test="header">
  <div class="content" fxLayoutAlign="space-between center">
    <img date-test="logo" src="assets/logo/engie.svg" />
    <div fxLayoutAlign="space-between center">
      <p data-test="name" class="name primary-blue">{{ userProfileName | titlecase }}</p>
      <mat-icon
        [attr.data-test]="'profile-menu'"
        class="profile-menu link"
        [matMenuTriggerFor]="menu"
        aria-label="user menu"
        >person</mat-icon
      >
    </div>
  </div>
</header>

<mat-menu #menu="matMenu">
  <button data-test="logout" mat-menu-item (click)="logoutHandler()">
    <mat-icon>logout</mat-icon>
    <span>Logout</span>
  </button>
</mat-menu>
