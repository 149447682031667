<div class="slider-container" fxLayoutAlign="start center">
  <label data-test="label">{{ label }}</label>
  <greenbackup-tooltip [tooltipText]="tooltipText"></greenbackup-tooltip>
  <div class="slider-label-container" fxLayout="column">
    <div class="slider-label" fxLayoutAlign="space-between center" *ngIf="showSliderLabel">
      <label class="min-max-label" *ngFor="let label of sliderLabel">{{ label }}</label>
    </div>
    <ngx-slider
      [attr.data-test]="'slider-' + label"
      class="criteria-slider"
      [options]="options"
      (valueChange)="onChangeSlider($event)"
    ></ngx-slider>
  </div>
</div>
