import { combineLatest, Observable } from 'rxjs';
import { delay, filter, first, map } from 'rxjs/operators';
import { FilteredTechnologies } from 'shared-models';
import { LIBRARY_ENERGY_STORAGE_OPTIONS } from 'shared-reference';

import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, UntypedFormBuilder } from '@angular/forms';

import { DatasheetsService, TechnologiesService } from '../../services';
import { ProjectDataRepository, TechnologiesRepository } from '../../store';

type LibraryFormGroup = {
  energyStorage: FormControl<string>;
};

@Component({
  selector: 'greenbackup-library',
  templateUrl: './library.component.html',
  styleUrls: ['./library.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LibraryComponent implements OnInit {
  filteredTechnologies$: Observable<Array<FilteredTechnologies>>;
  form: FormGroup<LibraryFormGroup>;
  energyStorage: AbstractControl;
  loading$ = this.technologiesRepository.loading$;
  lastUpdatedDate$ = this.profileDataRepository.lastUpdatedDate$;
  contactEmailAddresses$ = this.profileDataRepository.contactEmailAddresses$.pipe(map((emails) => emails.join(',')));
  isDownloadingFullDatasheet$ = this.datasheetsService.isDownloadingFullDatasheet$;
  datasheet: ArrayBuffer = undefined;

  constructor(
    private fb: UntypedFormBuilder,
    private technologiesRepository: TechnologiesRepository,
    private technologiesService: TechnologiesService,
    private profileDataRepository: ProjectDataRepository,
    private datasheetsService: DatasheetsService,
    @Inject(LIBRARY_ENERGY_STORAGE_OPTIONS) protected libraryEnergyStorageOptions: Array<string>,
  ) {}

  ngOnInit(): void {
    this.initForm();
    this.initFilterTechnologies();
    this.initialEnergyStorage();
  }

  initialEnergyStorage() {
    this.loading$
      .pipe(
        filter((loading) => !loading),
        delay(100),
        first(),
      )
      .subscribe(() => {
        this.energyStorage.setValue(this.libraryEnergyStorageOptions[0]);
      });
  }

  initFilterTechnologies() {
    this.filteredTechnologies$ = combineLatest([
      this.technologiesRepository.technologies$,
      this.technologiesRepository.othersTechnologies$,
      this.energyStorage.valueChanges,
    ]).pipe(
      map(([technologies, others, energyStorage]) =>
        [...technologies, ...others]
          .map(({ name, category }) => ({ name, category }))
          .filter((data) => data.category === energyStorage)
          .map((data) => ({
            name: data.name,
            style: this.technologiesService.getTechnologyButtonStyle(data),
          })),
      ),
    );
  }

  initForm(): void {
    this.form = this.fb.group({
      energyStorage: new FormControl<string>(''),
    });
    this.energyStorage = this.form.get('energyStorage');
  }

  onDownloadFullDatasheet() {
    this.datasheetsService.downloadFullDatasheet();
  }

  viewPDF(technologyName: string) {
    const technologyCategory = this.getTechnologyCategory(technologyName);
    this.form.disable();
    this.datasheetsService.getDatasheet(technologyCategory, technologyName).subscribe((data) => {
      this.form.enable();
      this.datasheetsService.viewDatasheet(data);
    });
  }

  private getTechnologyCategory(technologyName: string): string {
    const technologies = this.technologiesRepository.getAllEntities();
    const others = this.technologiesRepository.getOthers();

    return [...technologies, ...others].filter((technology) => technology.name === technologyName)[0].category;
  }
}
