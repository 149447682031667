import { ChartData, ChartOptions } from 'chart.js';

import { ChangeDetectionStrategy, Component, Input, OnInit, ViewChild } from '@angular/core';
import { ChartjsComponent } from '@ctrl/ngx-chartjs';
import { materialColor } from '../../utils/utils';

@Component({
  selector: 'greenbackup-radar-chart',
  templateUrl: './radar-chart.component.html',
  styleUrls: ['./radar-chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RadarChartComponent implements OnInit {
  chartData: ChartData;
  chartOptions: ChartOptions;
  private datasets: Array<any>;

  @ViewChild('ref', { static: true }) ref!: ChartjsComponent;
  @Input() chartTitle: string;
  @Input() legends: Array<{ name: string; color: string }> = [];
  @Input() labels: Array<string> = [];
  @Input() set data(data) {
    this.datasets = this.generateDatasets(data);
    this.updateChart(this.datasets);
  }

  ngOnInit(): void {
    this.initChartData();
    this.initChartOptions();
  }

  updateChart(data) {
    if (this.ref.chartInstance) {
      this.ref.chartInstance.data.datasets = data;
      this.ref.updateChart();
    }
  }

  private generateDatasets(data = []) {
    return data.map((datum) => {
      const technologyName = datum.label;
      const color = this.getTechnologyColor(technologyName);
      return {
        label: technologyName,
        data: datum.data,
        backgroundColor: `${color}33`, // 20% opacity
        borderColor: `${color}CC`, // 80% opacity
        pointBackgroundColor: color,
        pointBorderColor: color,
      };
    });
  }

  private getTechnologyColor(technologyName: string) {
    return this.legends.filter((legend) => legend.name === technologyName).shift()?.color || materialColor();
  }

  private initChartData(): void {
    this.chartData = {
      labels: this.labels,
      datasets: [
        {
          label: '',
          data: [],
          backgroundColor: [],
        },
      ],
    };
  }

  private initChartOptions() {
    this.chartOptions = {
      responsive: true,
      maintainAspectRatio: false,
      elements: {
        line: {
          borderWidth: 3,
          borderDash: [5],
        },
        point: {
          radius: 2,
          hoverRadius: 4,
          hitRadius: 20,
        },
      },
      scales: {
        r: {
          beginAtZero: true,
          min: 0,
          max: 5,
          ticks: {
            display: false,
            stepSize: 1,
          },
          pointLabels: {
            font: {
              size: 12,
            },
          },
        },
      },
      plugins: {
        legend: {
          display: false,
        },
      },
    };
  }

  onMouseEnter(index) {
    const datasets = this.datasets.filter((_, i) => index === i);
    this.updateChart(datasets);
  }

  onMouseLeave() {
    this.updateChart(this.datasets);
  }
}
