import { PagesLoadedEvent } from 'ngx-extended-pdf-viewer';

import { Component, Input } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';

import { DatasheetsService } from '../../services';
import { TechnologiesRepository } from '../../store';

@UntilDestroy()
@Component({
  selector: 'greenbackup-datasheets',
  templateUrl: './datasheets.component.html',
  styleUrls: ['./datasheets.component.scss'],
})
export class DatasheetsComponent {
  datasheet: ArrayBuffer = undefined;
  totalPages: number;
  page = 1;
  isDatasheetLoaded = false;
  fetchingPDF = true;
  hasTechnologySelected = false;

  _selectedTechnology: string = undefined;
  @Input() set selectedTechnology(technologyName: string) {
    this._selectedTechnology = technologyName;
    if (!technologyName) {
      return;
    }
    this.getDatasheet(technologyName);
  }

  constructor(private technologiesRepository: TechnologiesRepository, private datasheetsService: DatasheetsService) {}

  getDatasheet(technologyName: string) {
    this.resetDatasheetDisplayConfig();

    const technologyCategory = this.getTechnologyCategory(technologyName);
    this.datasheetsService.getDatasheet(technologyCategory, technologyName).subscribe((datasheet) => {
      this.datasheet = datasheet;
      this.fetchingPDF = false;
    });
  }

  afterDatasheetLoadingComplete(pdfData: PagesLoadedEvent) {
    this.totalPages = pdfData.pagesCount;
    this.isDatasheetLoaded = true;
  }

  nextPage() {
    this.page++;
  }

  prevPage() {
    this.page--;
  }

  onDownload() {
    this.datasheetsService.downloadDatasheet(this._selectedTechnology);
  }

  private resetDatasheetDisplayConfig() {
    this.hasTechnologySelected = true;
    this.fetchingPDF = true;
    this.totalPages = undefined;
    this.page = 1;
    this.isDatasheetLoaded = false;
    this.datasheet = undefined;
  }

  private getTechnologyCategory(technologyName: string): string {
    return this.technologiesRepository.getAllEntities().filter((technology) => technology.name === technologyName)[0]
      .category;
  }
}
