import { SharedModule } from 'src/app/shared';
import { RadarChartModule, TooltipModule } from 'src/app/shared/components';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { OverviewComponent } from './overview.component';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
  declarations: [OverviewComponent],
  imports: [CommonModule, RadarChartModule, SharedModule, TooltipModule, MatButtonModule, MatIconModule],
  exports: [OverviewComponent],
})
export class OverviewModule {}
