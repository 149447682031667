import { Injectable } from '@angular/core';
import { select } from '@ngneat/elf';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { OktaAuthStateService } from '@okta/okta-angular';
import { AuthState as OktaAuthState } from '@okta/okta-auth-js';
import { AuthState } from './auth-state.model';
import { authStore } from './auth.store';
@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class AuthRepository {
  state$ = authStore;
  loggedIn$ = authStore.pipe(select((state) => state.loggedIn));
  tokens$ = authStore.pipe(select((state) => state.tokens));
  user$ = authStore.pipe(select((state) => state.user));

  constructor(private authStateService: OktaAuthStateService) {
    this.authStateService.authState$.pipe(untilDestroyed(this)).subscribe(this.handleAuth.bind(this));
  }

  logout() {
    this.setState({
      loggedIn: false,
      tokens: null,
      user: null,
    });
    (this.authStateService as any).oktaAuth.signOut();
  }

  setState(nextState: AuthState) {
    authStore.update((state) => ({ ...state, ...nextState }));
  }

  getUsername() {
    return authStore.getValue().user.name;
  }

  handleAuth({ isAuthenticated, accessToken, idToken, refreshToken, error }: OktaAuthState) {
    if (!!error) {
      return;
    }
    if (isAuthenticated) {
      this.setState({
        loggedIn: true,
        tokens: {
          accessToken: accessToken!.accessToken,
          idToken: idToken!.idToken,
          refreshToken: refreshToken!.refreshToken,
          nonce: idToken!.claims.nonce!,
        },
        user: {
          id: idToken!.claims.sub,
          name: idToken!.claims.name!,
          email: idToken!.claims.email!,
        },
      });
    }
  }
}
