import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { ReactiveComponentModule } from '@ngrx/component';

import { DatasheetsComponent } from './datasheets.component';
import { FlexModule } from '@angular/flex-layout';

@NgModule({
  declarations: [DatasheetsComponent],
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    NgxExtendedPdfViewerModule,
    ReactiveComponentModule,
    FlexModule,
  ],
  exports: [DatasheetsComponent],
})
export class DatasheetsModule {}
