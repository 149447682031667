import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexModule } from '@angular/flex-layout';

import { SharedModule } from '../shared';
import { DescriptionModule } from './components/description';
import { CriteriaModule } from './containers/criteria';
import { LibraryModule } from './containers/library/library.module';
import { MainPageComponent } from './containers/main-page';
import { OverviewModule } from './containers/overview';
import { TechnologiesModule } from './containers/technologies/technologies.module';
import { CriteriaService, TechnologiesService } from './services';
import { CriteriaRepository, ProjectDataRepository, TechnologiesRepository } from './store';

@NgModule({
  declarations: [MainPageComponent],
  providers: [CriteriaRepository, CriteriaService, TechnologiesRepository, TechnologiesService, ProjectDataRepository],
  imports: [
    CommonModule,
    SharedModule,
    FlexModule,
    CriteriaModule,
    DescriptionModule,
    TechnologiesModule,
    OverviewModule,
    LibraryModule,
  ],
})
export class MainModule {}
