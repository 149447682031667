<section class="overview-container">
  <h2 class="section-header">
    Relevant technologies matching your criteria
    <greenbackup-tooltip
      *ngIf="!(criteriaLoading$ | ngrxPush)"
      [tooltipText]="'Overview.Overview'"
    ></greenbackup-tooltip>
  </h2>
  <article class="overview-article">
    <p>
      Disclaimer:<br />
      The merit order presented below only aims at filtering information in a relevant manner based on the criteria
      selected. It should, however, not be taken as granted for real implementation. Do not hesitate to adapt your
      criteria and navigate through the various technologies to learn more about Backup decarbonization.
    </p>

    <button
      data-html2canvas-ignore
      mat-flat-button
      color="primary"
      data-test="download-report"
      class="download-report"
      (click)="downloadReport()"
    >
      <mat-icon *ngIf="!isDownloadingReport; else spinner">save_alt</mat-icon>
      Download report
    </button>
  </article>

  <section class="graph-section">
    <h2>Overview</h2>
    <ng-container *ngIf="!(criteriaLoading$ | ngrxPush); else preloader">
      <div class="radar-chart-container">
        <greenbackup-radar-chart
          data-test="criteria-coverage"
          [chartTitle]="'Criteria coverage'"
          [legends]="technologiesColor$ | ngrxPush"
          [labels]="criteriaNames$ | ngrxPush"
          [data]="criteriaCoverageTechnologies$ | ngrxPush"
        ></greenbackup-radar-chart>
      </div>
      <div class="barchart-container">
        <greenbackup-bar-chart
          data-test="environment-rank-data-generators"
          [data]="environmentRankDataGenerators$ | ngrxPush"
          label="Production Assets Environmental performance* <br/>
      *taking into account CO2, SOx & physical footprint"
        ></greenbackup-bar-chart>

        <greenbackup-bar-chart
          data-test="global-rank-data-generators"
          [data]="globalRankDataGenerators$ | ngrxPush"
          label="Production Assets Global performance"
        ></greenbackup-bar-chart>

        <greenbackup-bar-chart
          data-test="environment-rank-data-storages"
          [data]="environmentRankDataStorages$ | ngrxPush"
          label="Storages Assets Environmental performance* <br/>
    *taking into account CO2, SOx & physical footprint"
        ></greenbackup-bar-chart>

        <greenbackup-bar-chart
          data-test="global-rank-data-storages"
          [data]="globalRankDataStorages$ | ngrxPush"
          label="Storages Assets Global performance"
        ></greenbackup-bar-chart>
      </div>
    </ng-container>
  </section>
</section>

<ng-template #preloader>
  <div gdAreas="radar radar | bar-a bar-b" gdGap="20px" class="preload-container">
    <ngx-skeleton-loader gdArea="radar" count="1" fxLayoutAlign="center" appearance="circle"></ngx-skeleton-loader>
    <ngx-skeleton-loader gdArea="bar-a" count="1"></ngx-skeleton-loader>
    <ngx-skeleton-loader gdArea="bar-b" count="1"></ngx-skeleton-loader>
  </div>
</ng-template>

<ng-template #spinner>
  <mat-icon [class.spinner]="true"></mat-icon>
</ng-template>
