import { Component, Input, ViewEncapsulation } from '@angular/core';

import { TooltipContextHolderService } from './services/tooltip-context-holder.service';

@Component({
  selector: 'greenbackup-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class TooltipComponent {
  _tooltipText: string;
  @Input() set tooltipText(text: string) {
    this._tooltipText = this.tooltipContextHolderService.getMessage(
      this.tooltipContextHolderService.getContextualKeys(text),
    );
  }

  get tooltipText() {
    return this._tooltipText;
  }

  @Input() tooltipPosition = 'right';

  constructor(private tooltipContextHolderService: TooltipContextHolderService) {}
}
