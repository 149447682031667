<div fxLayout="column" fxLayoutAlign="center center">
  <div *ngIf="!!legends?.length" class="legend-container" fxLayoutAlign="center center">
    <span
      *ngFor="let legend of legends; let i = index"
      fxLayoutAlign="center center"
      (mouseenter)="onMouseEnter(i)"
      (mouseleave)="onMouseLeave()"
      class="legend"
      [attr.data-test]="'legend-' + i"
    >
      <span class="legend-color" [style.backgroundColor]="legend.color"></span>
      {{ legend.name }}
    </span>
  </div>
  <ngx-chartjs type="radar" #ref [data]="chartData" [options]="chartOptions" height="400px"></ngx-chartjs>
  <label data-test="chart-label" *ngIf="chartTitle">{{ chartTitle }}</label>
</div>
