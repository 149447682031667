import { Injectable } from '@angular/core';

const RAW_TEXT = 'rawText';

@Injectable({
  providedIn: 'root',
})
export class TooltipContextHolderService {
  contextualData: any = undefined;
  isLoading = true;

  setContextualData(data: any) {
    this.contextualData = data;
    this.isLoading = false;
  }

  getContextualKeys(inputText: string) {
    const keyCombination = inputText.split('.');

    if (keyCombination.length !== 2) {
      return new ContextualKeys(RAW_TEXT, inputText);
    }

    const pageKey = keyCombination[0];
    const messageKey = keyCombination[1];

    if (!messageKey) {
      return new ContextualKeys(RAW_TEXT, pageKey);
    }

    return new ContextualKeys(pageKey, messageKey);
  }

  getMessage(contextualKeys: ContextualKeys) {
    if (!this.isLoading && !!this.contextualData) {
      try {
        if (contextualKeys.pageId === RAW_TEXT) {
          return contextualKeys.messageId;
        }
        return this.contextualData[contextualKeys.pageId][contextualKeys.messageId];
      } catch {
        return `${contextualKeys.pageId}.${contextualKeys.messageId}`;
      }
    }
  }
}

class ContextualKeys {
  pageId: string;
  messageId: string;

  constructor(pageId: string, messageId: string) {
    this.pageId = pageId;
    this.messageId = messageId;
  }
}
