import { SharedModule } from 'src/app/shared';
import { TooltipModule } from 'src/app/shared/components';

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

import { CriteriaComponent } from './criteria.component';

@NgModule({
  declarations: [CriteriaComponent],
  imports: [CommonModule, MatSlideToggleModule, SharedModule, ReactiveFormsModule, TooltipModule],
  exports: [CriteriaComponent],
})
export class CriteriaModule {}
