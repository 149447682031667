<section class="content">
  <h2 class="title" [attr.data-test]="'title'">Green back-up for energy supply</h2>

  <h2 class="description-title" [attr.data-test]="'description-title'">Decarbonize your back-up energy supply!</h2>
  <img class="blue-border" src="assets/images/blue-border.svg" alt="blue-border" />
  <section class="description-container">
    <article
      class="description"
      fxLayout="column"
      fxLayoutAlign="center center"
      *ngFor="let data of descriptions; let index = index"
      [attr.data-test]="'desc-' + (index + 1)"
    >
      <img [attr.src]="data.image" alt="list-svg-icon" />
      <div fxLayoutAlign="center center">
        <h3>{{ index + 1 }}.</h3>
        <p>{{ data.text }}</p>
      </div>
    </article>
  </section>
</section>
