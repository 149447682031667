import { createStore, withProps } from '@ngneat/elf';
import { withEntities } from '@ngneat/elf-entities';
import { NameCategory, Technology, TechnologyRank } from 'shared-models';

export const store = createStore(
  { name: 'technologies' },
  withEntities<Technology>(),
  withProps<{
    loading: boolean;
    rank: TechnologyRank;
    futureRank: TechnologyRank;
    categoriesColor: Record<string, string>;
    environmentRank: TechnologyRank;
    others: Array<NameCategory>;
    lastUpdatedDate: string;
  }>({
    loading: true,
    rank: [],
    futureRank: [],
    categoriesColor: {},
    environmentRank: [],
    others: [],
    lastUpdatedDate: '',
  }),
);
