import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BarChartComponent } from './bar-chart.component';
import { BarController, BarElement, Chart, CategoryScale, LinearScale, Title, Tooltip, Legend } from 'chart.js';
import { ChartjsModule } from '@ctrl/ngx-chartjs';
import { FlexLayoutModule } from '@angular/flex-layout';

@NgModule({
  declarations: [BarChartComponent],
  imports: [CommonModule, ChartjsModule, FlexLayoutModule],
  exports: [BarChartComponent],
})
export class BarChartModule {
  constructor() {
    Chart.register(BarController, BarElement, CategoryScale, LinearScale, Title, Tooltip, Legend);
  }
}
