import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexModule } from '@angular/flex-layout';

import { TooltipModule } from '../tooltip';
import { SliderComponent } from './slider.component';

@NgModule({
  declarations: [SliderComponent],
  imports: [CommonModule, FlexModule, NgxSliderModule, TooltipModule],
  exports: [SliderComponent],
})
export class SliderModule {}
