import { createStore, withProps } from '@ngneat/elf';

export const store = createStore(
  { name: 'project-data' },
  withProps<{
    loading: boolean;
    lastUpdatedDate: string;
    contactEmailAddresses: Array<string>;
  }>({
    loading: true,
    lastUpdatedDate: '',
    contactEmailAddresses: [],
  }),
);
