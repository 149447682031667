import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
@Component({
  selector: 'greenbackup-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  @Input() userProfileName: string;
  @Output() logout = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

  logoutHandler(): void {
    this.logout.emit();
  }
}
