import { Environment } from 'app-models';

export const environment: Environment = {
  name: 'production',
  production: true,
  auth: {
    issuer: 'https://et-tractebel.okta-emea.com/oauth2/default',
    clientId: '0oa8856k48F9JBSPQ0i7',
    redirectUri: window.location.origin + '/login/callback',
    scopes: ['email', 'openid', 'profile', 'offline_access', 'greenbackup'],
  },
  sentry: {
    dsn: 'https://8777d94b6a464fa2b1737ae0dda5c3be@o258615.ingest.sentry.io/6569591',
  },
  api: 'https://api.production.greenbackup.te-ded.com',
};
