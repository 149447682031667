import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EnergyVectorComponent } from './energy-vector.component';
import { MatIconModule } from '@angular/material/icon';
import { FlexModule } from '@angular/flex-layout';

@NgModule({
  declarations: [EnergyVectorComponent],
  imports: [CommonModule, MatIconModule, FlexModule],
  exports: [EnergyVectorComponent],
})
export class EnergyVectorModule {}
