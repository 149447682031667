import { BehaviorSubject, combineLatest } from 'rxjs';
import { debounceTime, filter, map } from 'rxjs/operators';
import { ComputeTechnologies, Criteria, Technology, TechnologyRank, TechnologyScore } from 'shared-models';
import { ENVIRONMENT_CRITERIA, ZERO_VALUE_CRITERIA_IN_FUTURE } from 'shared-reference';

import { Component, Inject, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { TechnologiesService } from '../../services';
import { CriteriaRepository, TechnologiesRepository } from '../../store';

@UntilDestroy()
@Component({
  selector: 'greenbackup-technologies',
  templateUrl: './technologies.component.html',
  styleUrls: ['./technologies.component.scss'],
})
export class TechnologiesComponent implements OnInit {
  selectedTechnology$ = new BehaviorSubject<string>(undefined);

  isLoaded$ = combineLatest([this.criteriaRepository.loading$, this.technologiesRepository.loading$]).pipe(
    map((loadings) => loadings.every((loading) => !loading)),
  );
  criteria$ = this.criteriaRepository.criteria$;
  energyVectors$ = this.criteriaRepository.energyVectors$;
  technologies$ = this.technologiesRepository.technologies$;
  backup$ = this.criteriaRepository.backup$;

  criteriaTechnologies$ = combineLatest([
    this.isLoaded$,
    this.criteria$,
    this.technologies$,
    this.backup$,
    this.energyVectors$,
  ]).pipe(
    debounceTime(500),
    filter(([isLoaded]) => isLoaded),
    map(([_, criteria, technologies, backup, energyVectors]) => ({
      criteria,
      technologies,
      backup,
      energyVectors,
    })),
  );

  private technologiesRank$ = this.technologiesRepository.technologiesRank$.pipe(
    map((technologies) =>
      technologies.filter((technology: TechnologyScore, index) => !isNaN(technology.value) && index <= 3),
    ),
  );

  private futureTechnologiesRank$ = this.technologiesRepository.futureTechnologiesRank$.pipe(
    map((technologies) =>
      technologies.filter((technology: TechnologyScore, index) => !isNaN(technology.value) && index <= 3),
    ),
  );

  techAndFutureTech$ = combineLatest([this.technologiesRank$, this.futureTechnologiesRank$]).pipe(
    map(([technologies, futureTechnologies]) => ({
      technologies: technologies.filter((d) => !!d.value),
      futureTechnologies: futureTechnologies.filter((d) => !!d.value),
    })),
  );

  firstTopTechnology$ = this.techAndFutureTech$.pipe(map(({ technologies }) => technologies[0]));

  constructor(
    private criteriaRepository: CriteriaRepository,
    private technologiesRepository: TechnologiesRepository,
    private technologiesService: TechnologiesService,
    @Inject(ZERO_VALUE_CRITERIA_IN_FUTURE) protected zeroValueCriteriaInFuture: Array<string>,
    @Inject(ENVIRONMENT_CRITERIA) protected environmentCriteria: Array<string>,
  ) {}

  ngOnInit(): void {
    this.initializeMainCriteriaSubscription();
    this.initializeFirstTopTechnologySubscription();
  }

  private getBackupMultiplier(techBackup: boolean, formBackup: boolean): number {
    if ((formBackup && techBackup) || !formBackup) {
      return 1;
    }
    return 0;
  }

  private computeTechnologyScore(
    criteria: Array<Criteria>,
    techCriteria: Array<Criteria>,
    techBackup: boolean,
    formBackup: boolean,
    hasFuel: boolean,
  ): number {
    let userCriteriaSum = 0;
    let criteriaSum = 0;

    criteria.forEach((criterion, index) => {
      userCriteriaSum += techCriteria[index].value;
      criteriaSum += criterion.value * techCriteria[index].value;
      criteriaSum *= this.getBackupMultiplier(techBackup, formBackup);
    });
    const score = criteriaSum / userCriteriaSum;
    return isNaN(score) || !hasFuel ? 0 : score;
  }

  private getFutureTechnologiesScore(data) {
    const setToZeroCriteria = this.zeroValueCriteriaInFuture;
    const criteria = data.criteria.map((criterion) =>
      setToZeroCriteria.includes(criterion.name)
        ? {
            ...criterion,
            value: 0,
          }
        : criterion,
    );
    const score = this.getTechnologiesScore({
      ...data,
      criteria,
    });
    return score;
  }

  private getEnvironmentScore(data) {
    const environmentCriteria = this.environmentCriteria;
    const criteria = data.criteria.filter((criterion) => environmentCriteria.includes(criterion.name));

    const scores = this.getTechnologiesScore({
      ...data,
      criteria,
    });
    return scores;
  }

  private getTechnologiesScore({ criteria, technologies, backup, energyVectors }: ComputeTechnologies): TechnologyRank {
    return technologies
      .map(({ name: techName, criteria: techCriteria, backup: techBackup, category, fuels }) => {
        const hasFuel = fuels.some((fuel) => energyVectors[fuel]);
        const name = techName;
        const value = this.computeTechnologyScore(criteria, techCriteria, techBackup, backup, hasFuel);
        return {
          name,
          category,
          value,
          fuels,
        };
      })
      .sort((a, b) => b.value - a.value);
  }

  private updateTechnologyRank(data) {
    const score = this.getTechnologiesScore(data);
    const futureScore = this.getFutureTechnologiesScore(data);
    const environmentScore = this.getEnvironmentScore(data);

    this.technologiesRepository.updateRank(score);
    this.technologiesRepository.updateFutureRank(futureScore);
    this.technologiesRepository.updateEnvironmentPerformance(environmentScore);
  }

  private initializeMainCriteriaSubscription() {
    this.criteriaTechnologies$.pipe(untilDestroyed(this)).subscribe((data: ComputeTechnologies) => {
      this.updateTechnologyRank(data);
    });
  }

  private initializeFirstTopTechnologySubscription() {
    this.firstTopTechnology$.pipe(untilDestroyed(this)).subscribe((data) => {
      this.onSelectTechnology(data?.name);
    });
  }

  onSelectTechnology(technologyName: string) {
    this.selectedTechnology$.next(technologyName);
  }

  getTechnologyButtonStyle(technology: Technology) {
    return this.technologiesService.getTechnologyButtonStyle(technology, this.selectedTechnology$.getValue());
  }
}
