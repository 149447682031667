import { Component, OnInit } from '@angular/core';

type Description = {
  image: string;
  text: string;
};

@Component({
  selector: 'greenbackup-description',
  templateUrl: './description.component.html',
  styleUrls: ['./description.component.scss'],
})
export class DescriptionComponent implements OnInit {
  descriptions: Array<Description> = [
    {
      image: 'assets/icons/list.svg',
      text: 'Choose the important criteria for your Green BackUp project.',
    },
    {
      image: 'assets/icons/decision-making.svg',
      text: 'Deep-dive in the technologies that could be relevant for your project.',
    },
    {
      image: 'assets/icons/plant.svg',
      text: 'See how current technologies could be more climate-friendly in the future.',
    },
  ];

  constructor() {}

  ngOnInit(): void {}
}
