import { Injectable } from '@angular/core';
import { TechnologiesRepository } from 'src/app/+main/store';
import { lightenColor, materialColor } from 'src/app/shared';
import { NameCategory } from 'shared-models';

@Injectable()
export class TechnologiesService {
  constructor(private technologiesRepository: TechnologiesRepository) {}

  getTechnologyButtonStyle(technology: NameCategory, technologyName?: string) {
    let border, color;
    this.technologiesRepository.categoriesColor$.subscribe((categoriesColor) => {
      color = categoriesColor[technology.category] ? categoriesColor[technology.category] : materialColor();

      if (technology.name === technologyName) {
        border = `3px solid ${color}`;
      }
    });

    return {
      border,
      'background-color': lightenColor(color),
    };
  }
}
