import { NgxExtendedPdfViewerService } from 'ngx-extended-pdf-viewer';
import { BehaviorSubject, Observable } from 'rxjs';
import { API_BASE_URL } from 'shared-tokens';

import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';

const APPLICATION_PDF_TYPE = 'application/pdf';
const FULL_DATASHEET_PDF_FILENAME = 'Green Back-up Technical datasheets.pdf';

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class DatasheetsService {
  isDownloadingFullDatasheet$ = new BehaviorSubject<boolean>(false);

  constructor(
    @Inject(API_BASE_URL) private apiBaseUrl: string,
    private http: HttpClient,
    private ngxService: NgxExtendedPdfViewerService,
  ) {}

  getDatasheet(category: string, itemName: string): Observable<ArrayBuffer> {
    return this.http.get(`${this.apiBaseUrl}/datasheets`, {
      params: { category, item: this.handleSpecialCharacter(itemName) },
      headers: { accept: APPLICATION_PDF_TYPE },
      responseType: 'arraybuffer',
    });
  }

  async downloadDatasheet(technologyName: string) {
    const datasheetBlob = await this.ngxService.getCurrentDocumentAsBlob();
    const fileName = `${technologyName}.pdf`;

    if (datasheetBlob) {
      const link = this.createLink({ download: fileName, href: URL.createObjectURL(datasheetBlob) });
      this.clickLink(link);
    }
  }

  async viewDatasheet(arraybuffer: ArrayBuffer) {
    const link = this.createLink({
      href: URL.createObjectURL(
        new Blob([arraybuffer], {
          type: 'application/pdf',
        }),
      ),
      target: '_blank',
    });
    link.removeAttribute('download');
    this.clickLink(link);
  }

  downloadFullDatasheet() {
    this.isDownloadingFullDatasheet$.next(true);

    this.http
      .get(`${this.apiBaseUrl}/datasheets`, {
        headers: { accept: APPLICATION_PDF_TYPE },
        responseType: 'arraybuffer',
      })
      .subscribe((fullDatasheet) => {
        const datasheetBlob = new Blob([fullDatasheet], { type: APPLICATION_PDF_TYPE });
        const link = this.createLink({
          download: FULL_DATASHEET_PDF_FILENAME,
          href: URL.createObjectURL(datasheetBlob),
        });
        this.clickLink(link);
        this.isDownloadingFullDatasheet$.next(false);
      });
  }

  private handleSpecialCharacter(queryParameter: string): string {
    return queryParameter.replace('+', '%2B');
  }

  private createLink({ href, target, download }: Partial<HTMLAnchorElement>): HTMLAnchorElement {
    const link = document.createElement('a');
    link.href = href;
    link.download = download;
    link.target = target;
    return link;
  }

  private clickLink(link: HTMLAnchorElement): void {
    document.body.append(link);
    link.click();
    link.remove();
    setTimeout(() => URL.revokeObjectURL(link.href), 7000);
  }
}
