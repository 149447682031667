import {
  Chart,
  Filler,
  Legend,
  LineElement,
  PointElement,
  RadarController,
  RadialLinearScale,
  Title,
  Tooltip,
} from 'chart.js';

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ChartjsModule } from '@ctrl/ngx-chartjs';

import { RadarChartComponent } from './radar-chart.component';
import { FlexModule } from '@angular/flex-layout';

@NgModule({
  declarations: [RadarChartComponent],
  imports: [ChartjsModule, CommonModule, FlexModule],
  exports: [RadarChartComponent],
})
export class RadarChartModule {
  constructor() {
    Chart.register(LineElement, PointElement, RadialLinearScale, RadarController, Filler, Legend, Title, Tooltip);
  }
}
