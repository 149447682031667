<section class="criteria-container">
  <h2 class="section-header">Main criteria</h2>
  <section class="criteria-section">
    <ng-container *ngIf="!(loading$ | ngrxPush); else preloader">
      <form [formGroup]="form">
        <div class="energy-vector-container">
          <h2>Energy Vectors</h2>
          <div
            fxLayoutAlign="start space-between"
            *ngIf="energyVectors$ | ngrxPush as energyVectors"
            formGroupName="energyVectors"
          >
            <greenbackup-energy-vector
              *ngFor="let energyVector of energyVectors"
              [name]="energyVector.name"
              [isSelected]="energyVector.value"
              [formControlName]="energyVector.name"
              (toggleChange)="onEnergyVectorToggle($event)"
            ></greenbackup-energy-vector>
          </div>
        </div>
        <div class="backup-container">
          <mat-slide-toggle
            formControlName="backup"
            [attr.data-test]="'backup'"
            labelPosition="before"
            (change)="onBackupChange($event)"
          >
            Backup only<greenbackup-tooltip [tooltipText]="'Criteria.Backup only'"></greenbackup-tooltip>
          </mat-slide-toggle>
        </div>
        <div class="main-criteria-container">
          <section *ngFor="let data of mainCriteria; index as i" [formGroupName]="data.name">
            <h2>{{ data.name }}</h2>
            <section class="child-criteria-container">
              <greenbackup-slider
                *ngFor="let childCriteria of data?.criteria; index as x"
                [attr.data-test]="childCriteria.name"
                [label]="childCriteria.name"
                [formControlName]="childCriteria.name"
                [showSliderLabel]="x === 0"
                [sliderLabel]="['Irrelevant', 'Crucial']"
                [tooltipText]="'Criteria.' + childCriteria.name"
                (sliderChange)="
                  onSliderChange({
                    value: $event,
                    id: i + 1,
                    name: childCriteria.name
                  })
                "
              ></greenbackup-slider>
            </section>
          </section>
        </div>
      </form>
    </ng-container>
  </section>
</section>

<ng-template #preloader>
  <div
    gdAreas="
    energy energy . . . . . .
    | backup . . . . . . .
    | criteria-title-a criteria-title-a . . criteria-title-b criteria-title-b . .
    | criteria-label-a criteria-a criteria-a criteria-a criteria-label-b criteria-b criteria-b criteria-b"
    gdGap="0 20px"
    class="preload-container"
  >
    <ngx-skeleton-loader gdArea="energy" count="1"></ngx-skeleton-loader>
    <ngx-skeleton-loader gdArea="backup" count="1"></ngx-skeleton-loader>
    <ngx-skeleton-loader gdArea="criteria-title-a" count="1"></ngx-skeleton-loader>
    <ngx-skeleton-loader gdArea="criteria-title-b" count="1"></ngx-skeleton-loader>
    <ngx-skeleton-loader gdArea="criteria-label-a" count="5"></ngx-skeleton-loader>
    <ngx-skeleton-loader gdArea="criteria-label-b" count="5"></ngx-skeleton-loader>
    <ngx-skeleton-loader gdArea="criteria-a" count="5"></ngx-skeleton-loader>
    <ngx-skeleton-loader gdArea="criteria-b" count="5"></ngx-skeleton-loader>
  </div>
</ng-template>
