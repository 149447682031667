import { AuthRepository } from 'core';
import html2canvas from 'html2canvas';
import { combineLatest } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { Technology, TechnologyCategory } from 'shared-models';
import { HtmlToPdfService } from 'src/app/shared';

import { Component, OnInit, ViewEncapsulation } from '@angular/core';

import { CriteriaRepository, TechnologiesRepository } from '../../store';

@Component({
  selector: 'greenbackup-overview',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class OverviewComponent implements OnInit {
  criterias$ = this.criteriaRepo.criteria$;
  criteriaNames$ = this.criterias$.pipe(map((criteria) => criteria.map((criterion) => criterion.name)));
  criteriaLoading$ = this.criteriaRepo.loading$;
  technologiesRankCategories$ = this.techRepo.technologiesRank$.pipe(map((data) => this.prepareLegend(data)));
  isDownloadingReport: boolean;
  globalRankDataGenerators$ = this.techRepo.technologiesRankWithColor$.pipe(
    map((techs) => this.filterByCategory(techs, TechnologyCategory.Generators)),
    map(this.prepareChartData),
  );
  environmentRankDataGenerators$ = this.techRepo.environmentRankWithColor$.pipe(
    map((techs) => this.filterByCategory(techs, TechnologyCategory.Generators)),
    map(this.prepareChartData),
  );

  globalRankDataStorages$ = this.techRepo.technologiesRankWithColor$.pipe(
    map((techs) => this.filterByCategory(techs, TechnologyCategory.Storages)),
    map(this.prepareChartData),
  );
  environmentRankDataStorages$ = this.techRepo.environmentRankWithColor$.pipe(
    map((techs) => this.filterByCategory(techs, TechnologyCategory.Storages)),
    map(this.prepareChartData),
  );

  private categoriesColor$ = this.techRepo.categoriesColor$;

  globalRankDataLegends$ = combineLatest([this.technologiesRankCategories$, this.categoriesColor$]).pipe(
    map(([techCategories, categories]) =>
      techCategories.map((name) => ({
        name,
        color: categories[name],
      })),
    ),
  );

  criteriaCoverageTechnologies$ = this.techRepo.criteriaCoverageTechnologiesWithColor$;

  technologiesColor$ = this.techRepo.criteriaCoverageTechnologiesWithColor$.pipe(
    map((criteriaCoverageTechnologies) =>
      criteriaCoverageTechnologies
        .filter((criteriaCoverageTechnology) => !!criteriaCoverageTechnology.data.length)
        .map((criteriaCoverageTechnology) => ({
          name: criteriaCoverageTechnology.label,
          color: criteriaCoverageTechnology.color,
        })),
    ),
  );

  private async generateReportPDF(): Promise<HTMLElement> {
    const elementToPrint = document.createElement('div');
    elementToPrint.classList.add('element-to-print');

    const authContainer = document.createElement('p');
    const name = this.authRepo.getUsername();
    authContainer.innerHTML = `<b>Author</b>: <span class="name">${name.toLocaleLowerCase()}<span>`;

    const dateContainer = document.createElement('p');
    const date = new Date().toLocaleString();
    dateContainer.innerHTML = `<b>Date</b>: ${date}`;

    const userDateDetails = document.createElement('div');
    userDateDetails.classList.add('user-date-details');
    userDateDetails.appendChild(authContainer);
    userDateDetails.appendChild(dateContainer);

    const criteriaContainer = document.querySelector('.criteria-container');
    const overviewContainerHeader = document.querySelector('.overview-container .section-header') as HTMLHeadingElement;
    overviewContainerHeader.style.pageBreakBefore = 'always';

    const overviewContainerArticle = document.querySelector('.overview-container .overview-article');

    const chartContainer = document.createElement('div');
    chartContainer.classList.add('overview-chart-container');

    const radar = await html2canvas(document.querySelector('greenbackup-radar-chart'));
    const environmentGenerators = await html2canvas(document.querySelector('greenbackup-bar-chart:nth-child(1)'));
    const globalGenerators = await html2canvas(document.querySelector('greenbackup-bar-chart:nth-child(2)'));

    const environmentStorages = await html2canvas(document.querySelector('greenbackup-bar-chart:nth-child(3)'));
    const globalStorages = await html2canvas(document.querySelector('greenbackup-bar-chart:nth-child(4)'));

    chartContainer.appendChild(radar);
    chartContainer.appendChild(environmentGenerators);
    chartContainer.appendChild(globalGenerators);
    chartContainer.appendChild(environmentStorages);
    chartContainer.appendChild(globalStorages);

    const techSection = document.querySelector('.technologies-section');

    elementToPrint.appendChild(userDateDetails);
    elementToPrint.append(criteriaContainer.cloneNode(true));
    elementToPrint.append(overviewContainerHeader.cloneNode(true));
    elementToPrint.append(overviewContainerArticle.cloneNode(true));
    elementToPrint.appendChild(chartContainer);
    elementToPrint.appendChild(techSection.cloneNode(true));

    return elementToPrint;
  }

  async downloadReport() {
    this.isDownloadingReport = true;
    const reportToPrint = await this.generateReportPDF();
    this.htmlToPdf.createCanvas(reportToPrint);
    this.htmlToPdf.downloadHTMLToPDF();
    this.isDownloadingReport = false;
  }

  constructor(
    private authRepo: AuthRepository,
    private techRepo: TechnologiesRepository,
    private criteriaRepo: CriteriaRepository,
    private htmlToPdf: HtmlToPdfService,
  ) {}

  ngOnInit(): void {}

  private filterByCategory(technologies: Array<Technology>, category) {
    return technologies.filter((technology) => technology.category === category);
  }

  private prepareChartData(technologies) {
    return {
      labels: technologies.map(({ name }) => name),
      data: technologies.map(({ value }) => value),
      colors: technologies.map(({ color }) => color),
    };
  }

  private prepareLegend(tech): Array<string> {
    return Array.from(
      new Set(
        tech
          .filter((d) => !!d.value)
          .map((d) => d.category)
          .sort(),
      ),
    );
  }
}
