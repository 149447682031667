import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  APIResponseTechnology,
  NameCategory,
  Technology,
  TechnologyCategory,
  TechnologyRank,
  TechnologyScore,
} from 'shared-models';
import { materialColor } from 'src/app/shared';

import { Injectable } from '@angular/core';
import { select } from '@ngneat/elf';
import { getAllEntities, getEntity, selectAllEntities, setEntities } from '@ngneat/elf-entities';

import { store } from './technologies.store';

@Injectable()
export class TechnologiesRepository {
  technologies$ = store.pipe(selectAllEntities());
  loading$ = store.pipe(select((state) => state.loading));
  technologiesRank$ = store.pipe(select((state) => state.rank));
  futureTechnologiesRank$ = store.pipe(select((state) => state.futureRank));
  environmentRank$ = store.pipe(select((state) => state.environmentRank));
  categoriesColor$ = store.pipe(select((state) => state.categoriesColor));
  othersTechnologies$ = store.pipe(select((state) => state.others));

  technologiesRankWithColor$ = combineLatest([this.technologiesRank$, this.categoriesColor$]).pipe(
    map(this.prepareRankWithColor.bind(this)),
  ) as Observable<Technology[]>;

  environmentRankWithColor$ = combineLatest([this.environmentRank$, this.categoriesColor$]).pipe(
    map(this.prepareRankWithColor.bind(this)),
  ) as Observable<Technology[]>;

  topTechnologiesRank$ = this.technologiesRank$.pipe(
    map((technologies) =>
      technologies.filter((technology: TechnologyScore, index) => !isNaN(technology.value) && index <= 3),
    ),
  );

  criteriaCoverageTechnologiesWithColor$ = combineLatest([this.technologies$, this.topTechnologiesRank$]).pipe(
    map(([technologies, topTechnologiesRank]) => {
      const topTechnologyNames = topTechnologiesRank.map((technology) => technology.name);
      const topTechHasValue = topTechnologiesRank.some((technology) => !!technology.value);
      return technologies
        .filter((technology) => topTechnologyNames.includes(technology.name))
        .map((technology) => ({
          label: technology.name,
          data: technology.criteria.filter(() => topTechHasValue).map((criterion) => criterion.value),
          color: this.getCriteriaCoverageColor(technology),
        }));
    }),
  );

  constructor() {}

  private getColor(category) {
    return category ? category : materialColor();
  }

  private getCriteriaCoverageColor(technology: Technology) {
    return technology.color ? technology.color : materialColor();
  }

  private prepareEntities(technologies: Array<APIResponseTechnology>): Array<Technology> {
    return technologies.map((technology, index) => {
      const id = index + 1;
      const name = Object.keys(technology)[0];
      const category = technology[name].category as TechnologyCategory;
      const backup = technology[name].backup_only;
      const criteria = Object.keys(technology[name].criteria).map((criteriaName) => ({
        name: criteriaName,
        value: technology[name].criteria[criteriaName],
      }));
      const fuels = technology[name].fuels;
      const color = technology[name].color;

      return {
        id,
        name,
        category,
        backup,
        criteria,
        color,
        fuels,
      };
    });
  }

  private prepareRankWithColor([technologies, categoriesColor]) {
    return technologies.map(({ category, name, value }) => ({
      name,
      value,
      color: this.getColor(categoriesColor[category]),
      category,
    }));
  }

  private prepareOthers(others): Array<NameCategory> {
    return others.map((name) => ({
      name,
      category: 'others',
    }));
  }

  initialize({
    technologies,
    categoriesColor,
    others,
  }: {
    technologies: Array<APIResponseTechnology>;
    categoriesColor: Record<string, string>;
    others: Array<string>;
  }): void {
    const entities = this.prepareEntities(technologies);
    store.update(setEntities(entities), (state) => ({
      ...state,
      others: this.prepareOthers(others),
      loading: false,
      categoriesColor: {
        ...categoriesColor,
        others: '#5d4037',
      },
    }));
  }

  getAllEntities(): Array<Technology> {
    return store.query(getAllEntities());
  }

  getEntity(id): Technology {
    return store.query(getEntity(id));
  }

  getOthers(): Array<NameCategory> {
    return store.getValue().others;
  }

  updateRank(rank: TechnologyRank): void {
    store.update((prevState) => ({
      ...prevState,
      rank,
    }));
  }

  updateFutureRank(futureRank: TechnologyRank): void {
    store.update((prevState) => ({
      ...prevState,
      futureRank,
    }));
  }

  updateEnvironmentPerformance(environmentRank: TechnologyRank): void {
    store.update((prevState) => ({
      ...prevState,
      environmentRank,
    }));
  }
}
